import { json, type MetaFunction, type LoaderFunction, redirect } from "@remix-run/node";

import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useMatches,
} from "@remix-run/react";

import { rootAuthLoader } from "@clerk/remix/ssr.server";

import "~/tailwind.css";
import "~/styles/index.css";

import { AppContextProvider } from "./context";
import { AlertMessageProvider } from "./context/AlertMessageContext";
import { FormErrorsProvider } from "./context/FormErrorContext";
import { IsLoadingProvider } from "./context/IsLoadingContext";
import { ClerkApp } from "@clerk/remix";
import { env } from "./env";
import IdleDetecter from "./components/IdleDetecter";

import { commitSession, getSession } from "./sessions";
import type { AlertProps } from "./types/alert";
import { WHITELISTED_DOMAINS } from "./constants/constants";
import { getUserById } from "./data/users.server";
import { Authorization } from "@prisma/client";

export const meta: MetaFunction = () => [
  { charset: "utf-8" },
  { title: "Call Center Directory" },
  { viewport: "width=device-width,initial-scale=1" },
];

function App() {
  const data = useLoaderData<typeof loader>();
  const matches = useMatches();

  const isEditOfficeLocationRoute = matches.some((match) => {
    return match.id === "routes/edit.office-location.$slug";
  });

  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
        {isEditOfficeLocationRoute ? (
          <script
            // src={`https://cdn.tiny.cloud/1/${data.ENV.TINY_MCE_API_KEY}/tinymce/6/tinymce.min.js`}
            src={`https://server-cdn.monarchy.io/tinymce/js/tinymce/tinymce.min.js`}
            referrerPolicy="origin"
          />
        ) : null}
      </head>
      <body>
        <AppContextProvider>
          <IsLoadingProvider>
            <AlertMessageProvider>
              <FormErrorsProvider>
                <Outlet />
              </FormErrorsProvider>
            </AlertMessageProvider>
          </IsLoadingProvider>
        </AppContextProvider>
        <ScrollRestoration />
        {
          <script
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
            }}
          />
        }
        <Scripts />
        <IdleDetecter />
      </body>
    </html>
  );
}

export const loader: LoaderFunction = async (args) => {
  return rootAuthLoader(
    args,
    async ({ request }) => {
      const { userId, sessionId, getToken } = request.auth;
      console.log("Root loader auth:", { userId, sessionId, getToken });

      if (userId && sessionId) {
        const fullUser = await getUserById(userId);

        const emailAddresses: string[] = [];

        fullUser.emailAddresses.forEach((email) => {
          emailAddresses.push(email.emailAddress);
        });

        for (const email of emailAddresses) {
          const emailDomain = email.split("@")[1];

          if (
            WHITELISTED_DOMAINS.every((domain) => {
              return emailDomain !== domain;
            }) &&
            !(
              new URL(request.url).pathname.includes("/sign-in") ||
              new URL(request.url).pathname.includes("/sign-up")
            )
          ) {
            try {
              const session = await getSession(request.headers.get("Cookie"));

              session.flash(
                "message",
                JSON.stringify({
                  type: "error",
                  title: "error",
                  message:
                    "The call center directory can be accessed only through a whitelisted domain email address.",
                } satisfies AlertProps),
              );

              return redirect("/sign-in", {
                headers: {
                  "Set-Cookie": await commitSession(session),
                },
              });
            } catch (err) {
              console.log(err);
            }
          }
        }

        if (fullUser.authorization === Authorization.CLIENT) {
          const pathname = new URL(request.url).pathname;
          if (
            !pathname.includes("/monitoring/dashboard") &&
            !pathname.includes("/monitoring/graphs") &&
            !pathname.includes("/user-profile")
          ) {
            throw redirect("/monitoring/dashboard?index&page=1&pageSize=10");
          }
        }
      }

      return json({
        ENV: {
          GOOGLE_MAPS_API_KEY: env.GOOGLE_MAPS_API_KEY,
          TINY_MCE_API_KEY: env.TINY_MCE_API_KEY,
        },
      });
    },
    {
      loadUser: true,
    },
  );
};

export function links() {
  return [
    {
      rel: "stylesheet",
      href: "https://use.typekit.net/rld3yui.css",
    },
  ];
}

export default ClerkApp(App);
