import { useAuth } from "@clerk/remix";
import { useCallback, useEffect, useRef, useState } from "react";

import { Button } from "~/components/ui/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog";

const IdleDetector = () => {
  const auth = useAuth();
  const dialogTimer = useRef<NodeJS.Timeout | null>(null);
  const logoutTimer = useRef<NodeJS.Timeout | null>(null);

  const [idle, setIdle] = useState(false);

  const resetDialogTimer = useCallback(() => {
    if (dialogTimer.current) {
      clearTimeout(dialogTimer.current);
    }

    dialogTimer.current = setTimeout(
      () => {
        console.log("idle");
        setIdle(true);
      },
      60 * 20 * 1000,
    );
  }, []);

  const startLogoutTimer = useCallback(() => {
    if (logoutTimer.current) {
      clearTimeout(logoutTimer.current);
    }

    logoutTimer.current = setTimeout(() => {
      setIdle(false);
      auth.signOut();
    }, 60000); // 60 seconds after dialog opens
  }, [auth]);

  useEffect(() => {
    if (!auth.isSignedIn) {
      setIdle(false);
      if (logoutTimer.current) clearTimeout(logoutTimer.current);
      if (dialogTimer.current) clearTimeout(dialogTimer.current);
      return;
    }

    const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];

    events.forEach((event) => {
      window.addEventListener(event, resetDialogTimer);
    });

    resetDialogTimer();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetDialogTimer);
      });
    };
  }, [resetDialogTimer, auth.isSignedIn]);

  useEffect(() => {
    if (idle) {
      startLogoutTimer();
    } else {
      if (logoutTimer.current) clearTimeout(logoutTimer.current);
    }
  }, [idle, startLogoutTimer]);

  return (
    <Dialog open={idle}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Idle detected</DialogTitle>
          <DialogDescription>
            You have been idle for a while. Do you want to continue working? If not, you will be
            logged out in 1 minute.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            className="bg-green-500 text-white"
            onClick={() => {
              setIdle(false);
              resetDialogTimer();
            }}
          >
            Continue Working
          </Button>
          <Button
            className="bg-blue-500 text-white"
            onClick={() => {
              setIdle(false);
              clearTimeout(dialogTimer.current!);
              auth.signOut();
            }}
          >
            Log Out
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default IdleDetector;
